<template>
  <v-app> 
    <v-main class="screen overflow-y-auto"  id="scrolling-techniques-8">
      <div class="screen "  > 
        <v-app-bar color="blue-grey" class='nav'>
          <div class="mt-2" style="display:flex;cursor: pointer;" @click="home()">
            <!-- <img src="@/assets/logo.png" class="mb-2" alt="star" style="width:160px" /> -->
            <!-- <h4 class="white--text mt-6" style="text-shadow:2px 2px 2px grey">경기학폭상담챗톡 Ver.1.0</h4> -->
          </div>
            <v-spacer></v-spacer>
            
            <v-responsive class="d-flex" v-if="$vuetify.breakpoint.mdAndUp">
            <div class="d-flex justify-center">
            <!-- <img src="@/assets/star1.png" class="mt-8" alt="star" style="width:50px" /> -->
            <!-- <h3 class="white--text " style="text-shadow:2px 2px 2px grey">경기학폭상담챗톡 Ver.1.0</h3> -->
            
            </div>
            <div class="d-flex justify-end">
              <v-btn-toggle v-model="menu"  tile color="#fff59d" group >
                <v-btn value="left" @click="menuSelect('c1')"><span class="white--text" style="font-weight: 900;"> 기본상담 </span></v-btn>
                <v-btn value="center"  @click="itemSelect('gpt')"> <span class="white--text" style="font-weight: 900;"> 개별상담 </span> </v-btn> 
                <v-btn value="right" @click="menuSelect('c3')"> <span class="white--text" style="font-weight: 900;"> 심층상담 </span> </v-btn> 
              </v-btn-toggle>
            </div>
          </v-responsive>
          <v-responsive v-else>
            <div class="d-flex justify-end">
            <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>
          </v-responsive>
        </v-app-bar> 
        
        <v-navigation-drawer v-model="drawer" absolute right temporary style="position: fixed;"  >
            <v-list nav dense >
              <v-list-item-group class="text-center" active-class="deep-purple--text text--accent-4" >
                <v-list-item  class="listItem" style="margin-top: 70px ">
                  <v-list-item-title class="my-2 py-3 pr-2" @click="menuSelect('c1');drawer=false"><h1>✨ 기본상담</h1></v-list-item-title>
                </v-list-item>
                <v-list-item class="listItem my-5">
                  <v-list-item-title class="my-2 py-3 pr-2" @click="itemSelect('gpt');drawer=false"><h1>✨ 개별상담</h1></v-list-item-title>
                </v-list-item>
                <v-list-item class="listItem">
                  <v-list-item-title class="my-2 py-3 pr-2" @click="menuSelect('c3');drawer=false;"><h1>✨ 심층상담</h1></v-list-item-title>
                </v-list-item> 
              </v-list-item-group>
            </v-list>
            <div class="pa-3" style="width:100%;position: absolute;bottom: 0; ">
              <v-btn block color="success" @click="cInfoTF=true">프로그램 제작정보</v-btn>
              <!-- <h4 class="text-center mb-3">소프트웨어 제작정보</h4>
              <h5>✨ 총괄 : <span style="font-weight: 100;"> 장학관 강문환</span></h5>
              <h5>✨ 지원 : <span style="font-weight: 100;"> 장학사 허은숙, 송은애</span></h5>
              <h5>✨ 개발 : <span style="font-weight: 100;"> 교사 김명석, 정재훈</span></h5>
              <h5>✨ 자료 : <span style="font-weight: 100;"> 교사 권재은, 박현진, 송재규, 이해진</span> </h5> -->
            </div>
          </v-navigation-drawer>
        <!-- <section class="hero">
          <div class="cta">경기학폭상담챗톡이<br />여러분의 학폭고민을 상담해드립니다.</div>
          <div class="right-triangle"></div>
          <v-btn class="button-cta" x-large  outlined @click="itemSelect('gpt')"> <h1> 챗톡상담</h1></v-btn>
          <button class="learn-more"   @click="itemSelect('gpt')" v-if="!c2"> <h1> 챗톡상담</h1></button>
          <button class="learn-more">Learn More</button>
        </section> -->
        
        <div>
          <!-- 최상단 빈공간 -->
          <section class="pa-8" style="background: #fff59d; text-align: center;">
            <!-- <img src="@/assets/star1.png" class="mt-8" alt="star" style="width:50px" />
            <h3 class="white--text " style="text-shadow:2px 2px 2px grey">경기학폭상담챗톡 Ver.1.0</h3> -->
          </section>
          <!-- 안내 -->
          <section  style="background: #fff59d;padding: 20px;text-align: center;" v-if="!c1&&!c2&&!c3"> 
            <h1  >✨학폭상담챗톡(Ver.1.0)✨</h1> 
            <v-row class="mt-3">
              <v-col  cols="12" sm="4"   >
                <img width="140" class="workImg" src="@/assets/star01.png"/> 
                <h3>어떤 서비스인가요?</h3> 
                <h4>학교폭력 사안에 대한 학부모 상담의 한계를 극복하고 접근성과 편의성을 용이하게 하기 위한 서비스 입니다.</h4> 
              </v-col>
              <v-col  cols="12" sm="4"   >
                <img width="140" class="workImg" src="@/assets/star02.png"/> 
                <h3>누가 이용하나요?</h3> 
                <h4>학교폭력에 대해 고민이 있는 초·중·고등학교에 소속된 학생, 학부모, 교사라면 누구나 이용 가능합니다.</h4> 
              </v-col>
              <v-col  cols="12" sm="4"   >
                <img width="140" class="workImg" src="@/assets/star03.png"/> 
                <h3>어떻게 진행되나요?</h3> 
                <h4>학교폭력과 관련된 내담자의 질문에 AI상담 챗톡이 개인 맞춤형 답변을 제공해 드립니다.</h4> 
              </v-col>
            </v-row>
          </section>

          <!-- 메뉴 -->
          <section class="pt-1" style="padding:0 20px;text-align: center;"   v-if="!c1&&!c2&&!c3" > 
            <h2 class="pt-7" >✨학교폭력에 관하여 도움을 드리겠습니다.</h2> 
            <v-row class="mt-3">
              <v-col  cols="12" md="4"   >
                <v-card class="mx-auto py-3" width="380"  color="blue-grey"    dark >
                  <v-card-title primary-title>
                    <v-btn color="#fff59d" elevation="5" class="mx-auto"  @click="menuSelect('c1')">
                      <h1 class="pa-5 black--text">기본상담</h1></v-btn>
                  </v-card-title>
                  <v-card-actions >
                    <div class="d-flex  mx-auto">
                      <img width="40" class="mt-2" src="@/assets/star01.png"/>
                      <h2 class="mt-5 mx-auto"> FAQ로 해결</h2>
                    </div>
                  </v-card-actions>
                  <h4 class="mt-5 mx-3">학폭 발생시 신고방법 및 처리절차 등</h4>
                  <h4  > 자주 묻는 기본 궁금증을 상담</h4>
                </v-card>
              </v-col>
              <v-col  cols="12" md="4"   >
                <v-card class="mx-auto py-3" max-width="380"  color="blue-grey"    dark >
                  <v-card-title primary-title>
                    <v-btn color="#fff59d" class="mx-auto" elevation="5"   @click="itemSelect('gpt')"><h1 class="pa-5 black--text">개별상담</h1></v-btn>
                  </v-card-title>
                  <v-card-actions >
                    <div class="d-flex  mx-auto">
                    <img width="40" class="mt-2" src="@/assets/star02.png"/>
                    <h2 class="mt-5 mx-auto"> 챗GPT상담 </h2>
                    </div>
                  </v-card-actions>
                  <h4 class="mt-5 mx-5">학폭 관련하여 남에게 쉽게 말하기</h4>
                  <h4  > 어려운 궁금증을 AI챗봇이 상담 </h4>
                </v-card>
                
              </v-col>
              <v-col  cols="12" md="4"   >
                <v-card class="mx-auto py-3" max-width="380"  color="blue-grey"    dark >
                  <v-card-title primary-title>
                    <v-btn color="#fff59d" elevation="5"  class="mx-auto" @click="menuSelect('c3')"><h1 class="pa-5 black--text">심층상담</h1></v-btn>
                  </v-card-title>
                  <v-card-actions >
                    <div class="d-flex  mx-auto">
                    <img width="40" class="mt-2" src="@/assets/star03.png"/>
                    <h2 class="mt-5 mx-auto"> 전문 상담기관 연결 </h2>
                    </div>
                  </v-card-actions>
                  <h4 class="mt-5 ">학폭 관련하여 심층적인 상담이 필요한 </h4>
                    <h4 >  경우 전문지원 및 업무담당 기관을 연결 </h4>
                </v-card>
                
              </v-col>
            </v-row>
          </section>


          
       <!-- 기본상담 -->
          <section class="text-center " style="position: relative;" v-if="c1">
            <v-btn color="#fff59d" fab  small  v-if="!type&&!type2&&!type3"  @click="home()" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn color="#fff59d" fab  small  v-if="type&&!type2&&!type3" @click="type ? type = '':''" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn color="#fff59d" fab  small  v-if="type2&&!type3" @click="type2 ? type2 = '':''" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn color="#fff59d" fab  small  v-if="type3" @click="type3 ? type3 = '':''" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
              <h1 style="font-size:30px;" class="mt-6">✨FAQ 기본상담</h1>
              <h1 style=" color:#f3bf02;" > {{ type }}</h1>
              <h1 style=" color:#f3bf02;" > {{ type2 }}</h1>
              <h1 style=" color:#f3bf02;" class="mb-6"> {{ type3 }}</h1>

            <!-- <h1 style="font-size:30px;" class="my-6" >✨FAQ <span style="font-size:28px;color: #f3bf02;"> {{ type3||type2||type }}</span></h1> -->
            <!-- <v-combobox class="ml-2" v-model="type" :items="types" label="질문유형"   dense filled outlined solo/>
            <v-combobox class="ml-2" v-model="type2" :items="filteredTypes2" label="카테고리1"   dense filled outlined solo v-if="hasValidOptions(filteredTypes2)"/>
            <v-combobox class="ml-2" v-model="type3" :items="filteredTypes3" label="카테고리2"   dense filled outlined solo v-if="hasValidOptions(filteredTypes3)"/> -->
            <!-- <h3>{{ type }} <span v-if="type"> → {{ type2 }}</span>  <span v-if="type2"> → {{ type3 }}</span> </h3> -->
            <v-row class="mt-3" v-if="!type">
              <v-col  cols="12" md="6"  v-for="(n,i) in types" :key="i"   >
                <v-card class="mx-auto pa-5" width="380"  color="blue-grey"    dark > 
                  <v-card-actions >
                    <div class="px-5  choice" @click="type=n" >
                        <h3  > {{ n }}</h3>  
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              </v-row>
            <v-row class="mt-3" v-if="!type2">
              <v-col  cols="12" md="6"  v-for="(n,i) in filteredTypes2" :key="i"   >
                <v-card class="mx-auto pa-5" width="380"  color="blue-grey"    dark > 
                  <v-card-actions >
                    <div class="px-5  choice" @click="type2=n" >
                        <h3  > {{ n }}</h3>  
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              </v-row>
            <v-row class="mt-3"  v-if="!type3">
              <v-col  cols="12" md="6"  v-for="(n,i) in filteredTypes3" :key="i"   >
                <v-card class="mx-auto pa-5" width="380"  color="blue-grey"    dark > 
                  <v-card-actions >
                    <div class="px-5  choice" @click="type3=n" >
                        <h3  > {{ n }}</h3>  
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              </v-row>
            <!-- <div class="mt-12" style="display: flex; justify-content: flex-start;overflow-x: auto;" v-if="!type">
              <v-btn color="success" class="mx-2" v-for="(n,i) in types" :key="i" @click="type=n" >{{ n }}</v-btn>
            </div>
            <div class="mt-5" style="display: flex; justify-content: flex-start;overflow-x: auto;" v-if="!type2">
              <v-btn fab small color="info" @click="type=''" v-if="type"><v-icon>mdi-arrow-left-bold</v-icon> </v-btn>
              <v-btn color="info" class="mx-2" v-for="(n,i) in filteredTypes2" :key="i" @click="type2=n" >{{ n }}</v-btn>
            </div>
            <div class="mt-5" style="display: flex; justify-content: flex-start;overflow-x: auto;" >
              <v-btn fab small color="error" @click="type2=''" v-if="type2"><v-icon>mdi-arrow-left-bold</v-icon> </v-btn>
              <v-btn color="error" class="mx-2" v-for="(n,i) in filteredTypes3" :key="i" @click="type3=n" >{{ n }}</v-btn>
            </div> -->
          </section>
             <!-- 에어테이블 FAQ -->  
             <section v-if="c1" >
            <v-row class="mt-3 px-5 pb-14" >
              <v-col v-for="(n, i) in items.filter( (n) => workoutFilter(n.type,n.type2,n.type3) )" :key="i" cols="12" sm="6" md="6"   >
                <v-card :loading="loading" class="mx-auto pa-3" style="background: #aacfd0" @click="itemSelect(n)" >
                    <div class=" menuBox  "  >
                        <div style="align-items: center;display: flex;">
                          <img width="60" class="workImg" src="@/assets/qstar.png"/> 
                          <span class="mx-3 ">{{ n.title }} </span>
                        </div> 
                    </div>
                </v-card>
              </v-col>
            </v-row>
          </section>

            <!-- 심층상담 -->
            <section class="text-center " style="position: relative;" v-if="c3">
            <v-btn color="#fff59d" fab  small  v-if="!type&&!type2&&!type3"  @click="home()" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn color="#fff59d" fab  small  v-if="type&&!type2&&!type3" @click="type ? type = '':''" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn color="#fff59d" fab  small  v-if="type2&&!type3" @click="type2 ? type2 = '':''" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn color="#fff59d" fab  small  v-if="type3" @click="type3 ? type3 = '':''" style="position: absolute; left: 10px;"><v-icon>mdi-arrow-left</v-icon></v-btn>
              <h1 style="font-size:30px;" class="mt-6">✨심층상담</h1>
              <h1 style=" color:#f3bf02;"  >  {{ type }} </h1>
              <h1 style=" color:#f3bf02;" class="mb-6">  {{ type2 ? "(" + type2 + ")" : '' }} </h1>

            <v-row class="mt-3" v-if="!type">
              <v-col  cols="12" md="6"  v-for="(n,i) in types" :key="i"   >
                <v-card class="mx-auto pa-5" width="380"  color="blue-grey"    dark > 
                  <v-card-actions >
                    <div class="px-5  choice" @click="type=n" >
                        <h3  > {{ n }}</h3>  
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              </v-row>
            <v-row class="mt-3" v-if="!type2">
              <v-col  cols="12" md="6"  v-for="(n,i) in filteredTypes2" :key="i"   >
                <v-card class="mx-auto pa-5" width="380"  color="blue-grey"    dark > 
                  <v-card-actions >
                    <div class="px-5  choice" @click="type2=n" >
                        <h3  > {{ n }}</h3>  
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              </v-row>
            <!-- <v-row class="mt-3"  v-if="!type3">
              <v-col  cols="12" md="6"  v-for="(n,i) in filteredTypes3" :key="i"   >
                <v-card class="mx-auto py-3" width="380"  color="blue-grey"    dark > 
                  <v-card-actions >
                    <div class="d-flex  mx-auto">
                      <h2 class="mt-5 mx-auto"> {{ n }}</h2>
                    </div>
                  </v-card-actions>
                    <v-btn color="#fff59d" elevation="5" class="mx-auto my-7" @click="type3=n">
                      <h1 class="pa-5 black--text">선 택</h1></v-btn>
                </v-card>
              </v-col>
              </v-row>  -->
          </section>
             <!-- 에어테이블 FAQ -->  
             <section v-if="c3" >
            <v-row class="mt-3 px-5 pb-14" >
              <v-col v-for="(n, i) in items.filter( (n) => workoutFilter(n.type,n.type2,n.type3) )" :key="i" cols="12" sm="6" md="6"   >
                <v-card :loading="loading" class="mx-auto pa-3" style="background: #aacfd0"  >
                    <div class=" menuBox  "  >
                        <div style="align-items: center;display: flex;">
                          <!-- <img width="60" class="workImg" src="@/assets/star03.png"/>  -->
                          <h3 class="mx-auto my-5">{{ n.title }} </h3>
                        </div> 
                        <div class="d-flex">
                          <v-btn class="mx-auto mb-5" large color="success"  @click="makeCall(n.content)"><h1>☎ {{ n.content }}</h1></v-btn>
                        </div>
                    </div>
                </v-card>
              </v-col>
            </v-row>
          </section>

          
             <!-- 챗GPT -->
             <section  class="mb-10" v-if="c2" style="position: relative;">
               <iframe src="https://www.chatbase.co/chatbot-iframe/HRh69oRZDdp5ctHwaBWcL"  width="100%" height="650px" frameborder="0" ></iframe> 
              <div class="d-flex "  style="position: absolute;bottom:1px;background: white;width: 100%;" >
                <v-btn text dark class="mx-auto" color="white"  >처음으로</v-btn>
              </div>
            </section>
          
            <!-- 일반 상담유의사항 다이얼로그 -->
            <v-dialog    scrollable v-model="dialog1"  :overlay="false" max-width="500px" transition="dialog-transition" >
            <v-card class="pa-5">
              <div class="d-flex" >
                <img width="60px" height="90px" class="mr-3" src="@/assets/astar.png"/> 
                <h2 class="mt-4" v-if="cards.alert"  >챗톡상담 유의사항</h2>
                <h3 class="mt-3" v-if="cards.title"  >{{ cards.title }}</h3>
              </div>
                <span class="mx-auto text-left" style="white-space: pre-line;" v-if="cards.content" >{{ cards.content }}</span>  
                <span class="mx-auto text-left" style="white-space: pre-line;" v-else >{{ cards.desc }}</span>  
                <v-btn color="amber" dark class="mt-5" @click="c2TF=false" v-if="cards.content">닫기</v-btn>
                <div class="text-center" v-else>
                  <v-checkbox v-model="checkbox" label="위 내용에 동의합니다." ></v-checkbox>
                  <v-btn color="amber" block  class="mt-5" @click="c2=true;c1=false;c3=false;c2TF=false" :disabled="!checkbox" >상담하기</v-btn>
                </div>
            </v-card>
           </v-dialog>  
            <!-- 챗GPT 상담유의사항 다이얼로그 -->
            <v-dialog  v-model="c2TF" scrollable   :overlay="false" max-width="500px" transition="dialog-transition" >
            <v-card class="pa-5">
              <div class="d-flex" >
                <img width="60px" height="90px" class="mr-3" src="@/assets/astar.png"/> 
                <h2 class="mt-4" v-if="cards.alert"  >챗톡상담 유의사항</h2>
                <h3 class="mt-3" v-if="cards.title"  >{{ cards.title }}</h3>
              </div>
                <span class="mx-auto text-left" style="white-space: pre-line;" v-if="cards.content" >{{ cards.content }}</span>  
                <span class="mx-auto text-left" style="white-space: pre-line;" v-else >{{ cards.desc }}</span>  
                <v-btn color="amber" dark class="mt-5" @click="c2TF=false" v-if="cards.content">닫기</v-btn>
                <div class="text-center d-flex" v-else>
                  <v-btn color="amber" dark class="mt-5" style="width: 48%;" @click="c2=true;c1=false;c3=false;c2TF=false"  >동의</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="amber" dark class="mt-5" style="width: 48%;" @click="menuSelect('c3')"  >미동의</v-btn>
                </div>
            </v-card>
           </v-dialog>  

            <!-- 제작자정보 -->
            <v-dialog  v-model="cInfoTF" scrollable   :overlay="false" max-width="500px" transition="dialog-transition" >
            <v-card class="pa-5">
              <!-- <div class="d-flex" >
                <img width="60px" height="90px" class="mr-3" src="@/assets/astar.png"/> 
              </div> -->
              <h2 class="mx-auto mb-4" >⭐프로그램 제작정보⭐</h2>
                <span class="mx-auto text-center" style="white-space: pre-line;"  >{{ createInfo }}</span>  
                <v-btn color="amber" dark class="mt-5" @click="cInfoTF=false"  >닫기</v-btn>
                 
            </v-card>
           </v-dialog>  

                


          <!-- 자동슬라이드 FAQ -->
          <section class="coments mt-8" v-if="!c1&&!c2&&!c3">
            <v-carousel cycle height="180" hide-delimiters hide-delimiter-background show-arrows-on-hover>
              <v-carousel-item v-for="(n, index) in items" :key="index">
                <div class="box-clients" @click="itemSelect(n)" style="cursor: pointer;">
                  <p class="speech d-flex">
                    <img width="60" height="80" class="workImg" src="@/assets/qstar.png"/> 
                    <span class="mt-2 ml-3"> {{ n.title }} </span>
                   </p>
                </div>
              </v-carousel-item>
            </v-carousel>
          </section>

          <section class="coments2 d-flex" style="overflow-x: auto; white-space: nowrap;" v-if="!c1&&!c2&&!c3">
              <div v-for="(n, i) in youtubes" :key="i" class="mr-5">
                  <div class="box-clients" @click="opensite(n.link)" style="cursor: pointer; display: inline-block;">
                      <img width="300" height="200" :src="n.img"/> 
                      <h6 class="text-center">{{ n.title }}</h6>
                  </div>
              </div>
          </section>

          <div style="height:65px;background-color: #80a7a8;" v-if="!c1&&!c2&&!c3"></div>
          <section class="footer">
            <div>&copy;RunBuilder. All rights reserved.</div>
            <!-- <div>&copy;2023경기도교육청. All rights reserved.</div> -->
          </section>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data () {
    return {
      menu: false,
      drawer: false,
      loaded: false,
      c1: false,
      c2: false,
      c3: false,
      dialog1:false,
      cInfoTF: false,
      checkbox: false,
      version: '0701',
      getItems1: [],
      getItems2: [],
      items: [],
      youtubes: [],
      cards:{},
      chatgpt:{
        alert:'챗톡상담 유의사항',
        desc:'학교폭력상담챗톡 Ver1.0(이하 "챗톡"이라고 칭함)은 AI 챗Gpt 기반의 상담 서비스로 7월은시범 운영 기간입니다.\n'+
        ' 챗톡 이용자는 상담이 진행되는 과정에서 민감 정보를 포함한 개인정보 일체를 입력해서는 안 되며,'+
        '상담 내용은 기술적 한계로 인해 부정확하거나 틀릴 수 있음을 고지하는 바입니다.\n'+
        // ' 챗톡 상담 내용은 중요한 의사결정의 판단 자료로 사용되기에는 부적합하며, '+
        // ' 경기도 교육청과 운영진은 챗톡 상담 내용과 관련하여 민·형사·행정상의 어떠한 책임도 지지 않습니다.\n'+
        '정확한 상담을 원하신다면 학교, 교육지원청, 도 교육청 업무 담당자에게 문의 하시기 바랍니다.\n\n' +
        '이에 대한 동의 여부를 체크하고 상담을 진행해 주시기 바랍니다.',
        // desc:'경기 학교폭력 상담 챗톡 ver1.0은 AI 챗Gpt기반의 상담서비스입니다. 상담 시 비공개 정보나 개인정보를 입력하지 않으며 챗Gpt기반 상담 특성상 상담 내용은 정확하지 않을 수 있으니 정확한 내용은 학교, 교육지원청, 도교육청 업무담당자에게 확인하시기 바랍니다. 운영진은 상담서비스 내용에 대한 법적인 책임이 없으며 이에 대한 동의여부를 체크하고 상담을 진행해 주시기 바랍니다. ',
        confirm:'상담하기'
      },
      createInfo:'런빌더',
    //   createInfo:'[총괄]\n'+
    //   '경기도교육청 생활인성교육과\n'+'과장 서은경, 장학관 강문환\n\n'+
    //  '[기획]\n'+
    //  '경기도교육청 생활인성교육과\n'+ '장학사 송은애\n'+
    //  '경기도안양과천교육지원청\n'+ '장학사 허은숙\n\n'+                 
    //   '[소프트웨어 개발]\n'+
    //   '안산해양중학교 교사 김명석\n'+
    //   '매향중학교    교사 정재훈\n\n'+
    //   '[자료협조]\n'+
    //   '분당중앙고등학교 교사 권재원\n'+
    //      '광명초등학교     교사 박현진\n'+
    //      '지산고등학교     교사 송재규\n'+
    //      '성사중학교       교사 이해진',
     // c2:false,
      c2TF:false,
      loading:false,
      type:'',
      types:['관계회복 화해중재','학교폭력 개념과 유형','피해 및 가해학생 조치','심의 운영회'],
      type2:'',
      types2:[],
      type3:'',
      types3:[],
      getTypes1:[],
      getTypes2:[],
      allTypes:[],
      allTypes1:[],
      allTypes2:[],
    filteredTypes: [],
    filteredTypes2: [],
    filteredTypes3: [], 
    }
  },
  created () {
    this.getV()
    this.getAirtable();
    this.getAirtable2();
    this.getYoutube();
  },
  mounted() {
    this.filteredTypes = this.uniqueTypes;
  },
  computed: {
    uniqueTypes() {
      console.log(this.allTypes)
      return [...new Set(this.allTypes.map(item => item.type))];
    },
    uniqueTypes2() {
      console.log(this.allTypes)
      return [...new Set(this.allTypes.filter(item => item.type === this.type).map(item => item.type2))];
    },
    uniqueTypes3() {
      console.log(this.allTypes)
      return [...new Set(this.allTypes.filter(item => item.type2 === this.type2).map(item => item.type3))];
    }
  },
  watch: {
    type(newType) {
      this.type2 = '';
      this.type3 = '';
        // items.sort((a, b) => a.sort - b.sort);
      console.log(this.uniqueTypes2)
      this.filteredTypes2 = this.uniqueTypes2;
    },
    type2(newType2) {
      this.type3 = '';
      console.log(this.uniqueTypes3)
      this.filteredTypes3 = this.uniqueTypes3;
    },
    // items(newItem) {
    //   this.items = newItem;
    //   console.log('감시',newItem)
    // }
  },
  methods: {
    home(){
      this.c1=false
      this.c2=false
      this.c3=false
      this.type=''
      this.type2=''
      this.type3=''
      this.items = this.getItems1
    },
    makeCall(phoneNumber) {
    window.location.href = 'tel:' + phoneNumber;
  },
    hasValidOptions(options) {
      return options.filter(option => option !== undefined).length > 0;
    },
    async getV () {
        const API_KEY = process.env.VUE_APP_AIRTABLE;
        const airtableUrl = `https://api.airtable.com/v0/appAvV0A13p3b96vc/version`;
        const headers = {
        Authorization: `Bearer ${API_KEY}`
      };
      try {
        const response = await fetch(airtableUrl, { headers });
        const json = await response.json();
        const getVersion = json.records[0].fields.version

        if (getVersion !== this.version) {
            this.reload()
            }
      } catch (error) {
        console.log(error);
      }
    },
    async reload () {
      caches
        .keys().then(c => {
          for (const i of c) {
            caches.delete(i)
          }
        })
        .then(() => {
          location.reload(true)
        })
    },
    jump(sectionId) {
      const container = document.querySelector(".page");
      const scrollTo = document.getElementById(sectionId);
      container.scrollTo({
        top: scrollTo.offsetTop - container.offsetTop + container.scrollTop - 70,
        behavior: "smooth"
      });
    },
    //에어테이블 읽기
    async getAirtable() {
      try {
      const API_KEY = process.env.VUE_APP_AIRTABLE;
      const airtableUrl = `https://api.airtable.com/v0/appAvV0A13p3b96vc/question`;
      const headers = {
        Authorization: `Bearer ${API_KEY}`
      };

      const items = [];
        let offset;
        //에어테이블에서 한번에 가져올 수 있는 데이터양은 100개이므로 반복문으로 페이지네이션 작업필요
        while (true) {
          const response = await fetch(`${airtableUrl}?offset=${offset || ''}`, { headers });
        // const response = await fetch(airtableUrl, { headers });
        const json = await response.json();
        const records =  json.records.map(record => ({
          type: record.fields.type,
          type2: record.fields.type2,
          type3: record.fields.type3,
          title: record.fields.title,
          content: record.fields.content,
          link: record.fields.link,
          sort: record.fields.sort,
        }));
        items.push(...records);
          offset = json.offset;
          // console.log(workItems)
          if (!offset) {
            break;
          }
        }
        this.getItems1 = items
        this.items = items
        items.sort((a, b) => a.sort - b.sort);
        const types =  [...new Set(items.map(item => item.type))].filter(type => type !== undefined); ;
        this.types = types; 
        this.getTypes1 = types; 
        const allTypes = [...new Set(items.map((a)=>{
          return {type:a.type,type2:a.type2,type3:a.type3}
        }))]
        this.allTypes=allTypes;
        this.allTypes1=allTypes;
        // console.log(allTypes)
 
      } catch (error) {
        console.error(error);
      }
    }, 
    async getAirtable2() {
      try {
      const API_KEY = process.env.VUE_APP_AIRTABLE;
      const airtableUrl = `https://api.airtable.com/v0/appAvV0A13p3b96vc/tel`;
      const headers = {
        Authorization: `Bearer ${API_KEY}`
      };
      const items = [];
        let offset;
        //에어테이블에서 한번에 가져올 수 있는 데이터양은 100개이므로 반복문으로 페이지네이션 작업필요
        while (true) {
        // const response = await fetch(airtableUrl, { headers });
          const response = await fetch(`${airtableUrl}?offset=${offset || ''}`, { headers });
        const json = await response.json();
        const records =  json.records.map(record => ({
          type: record.fields.type,
          type2: record.fields.type2,
          type3: '',
          title: record.fields.title,
          content: record.fields.content,
          link: record.fields.link||'',
        }));
        items.push(...records);
          offset = json.offset;
          // console.log(workItems)
          if (!offset) {
            break;
          }
        }
        console.log(items)
        
        this.getItems2 = items
        const types =  [...new Set(items.map(item => item.type))].filter(type => type !== undefined); ;
        this.getTypes2 = types; 
        // this.types = types; 
        const allTypes = [...new Set(items.map((a)=>{
          return {type:a.type,type2:a.type2}
          // return {type:a.type,type2:a.type2,type3:a.type3}
        }))]
        this.allTypes2=allTypes;
        // this.allTypes=allTypes;
        // console.log(allTypes)
 
      } catch (error) {
        console.error(error);
      }
    }, 
    async getYoutube(){
      
      const API_KEY = process.env.VUE_APP_AIRTABLE;
      const airtableUrl = `https://api.airtable.com/v0/appAvV0A13p3b96vc/link`;
      const headers = {
        Authorization: `Bearer ${API_KEY}`
      };
      try {
        const response = await fetch(airtableUrl, { headers });
        const json = await response.json();
        const items =  json.records.map(record => ({
          title: record.fields.title,
          link: record.fields.link,
          img: record.fields.img,
          sort: record.fields.sort
        }));
        items.sort((a, b) => a.sort - b.sort);
        
        this.youtubes = items
        // console.log(items)
      }catch (error) {
        console.error(error);
      }
    },
    itemSelect(n){
        // this.c1 = false;
      this.c2TF = true
      if(n==='gpt'){
        this.cards = this.chatgpt
      }else{
        this.cards = n
      }
      // if (n.link < 10) { return }
      // window.open(n.link)
    },
    menuSelect(n){
      this.c2TF = false;
      if(n==='c1'){
        // this.getAirtable()
        this.items = this.getItems1
        this.types = this.getTypes1; 
        this.allTypes = this.allTypes1; 
        this.c1 = true;
        this.c2 = false;
        this.c3 = false;
        this.type=''
      this.type2=''
      this.type3=''
      }else{
        this.items =this.getItems2
        this.types = this.getTypes2; 
        this.allTypes = this.allTypes2; 
        this.drawer = false;
        // this.getAirtable2()
        this.c1 = false;
        this.c2 = false;
        this.c3 = true;
        this.type=''
      this.type2=''
      this.type3=''
      } 
    },
    opensite(link){
      window.open(link)
    },  
    workoutFilter(type, type2, type3) { 
      return type === this.type && type2 === this.type2 && type3 === this.type3;
    },


  }
};
</script>

<style>
body,
html {
  height: 100%;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding: 0;
  background-color: #aacfd0;
  overflow-y: auto;
}

.subject {
  font-family: 'Playfair Display', serif;
  color: rgba(255, 255, 255, 0.07);
  font-size: 180px;
  font-size: 16vw;
  letter-spacing: -4px;
  line-height: 0.9;
}

  .listItem {
    background-color: #fff59d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .listItem:hover {
    background-color: #aacfd0;
  }
/* .screen {
  width: 100%;
  height: 80vh;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, #f15f79, #b24592);
} */

.nav {
  width: 100%;
  height: 66px;
  position: fixed;
  z-index: 9999;
  transition: all 0.3s;
}


.nav>nav {
  float: right;
}

.button-nav {
  height: 50px;
  padding: 0px 16px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  position: relative;
  float: left;
  background-color: transparent;
  border-bottom: 5px transparent solid;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s;
}

.button-nav:hover {
  cursor: pointer;
  height: 40px;
  color: white;
  border-bottom: 5px white solid;
}

.page {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
}

.section-name {
  font-size: 12px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.2);
}

.section-title {
  font-size: 36px;
  letter-spacing: -2px;
  color: #f3bf02;
  text-align: center;
  margin-bottom: 4px;
}

.section-subtitle {
  text-align: center;
}

.section-subtitle a {
  height: 26px;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: rgba(243, 191, 2, 0.5);
  padding: 2px 8px;
  border: 1px solid rgba(243, 191, 2, 0.5);
  border-radius: 13px;
}

.negative {
  color: #000;
}

.hero {
  position: relative;
  height: 485px;
  padding-top: 60px;
  padding-left: 45px;
  background: url('https://cdn.pixabay.com/photo/2021/02/26/20/19/sisters-6053044_1280.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cta {
  height: 260px;
  width: 420px;
  border: 12px solid white;
  margin-top: 80px;
  padding: 20px;
  font-weight: 800;
  font-size: 38px;
  color: white;
  box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.4);
}



.button-cta,
.button-nav-cta,
.button-sec-cta {
  padding: 2px 23px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
  color: white;
  border-radius: 12px;
  transition: all 0.3s;
}

.button-cta {
  height: 46px;
  position: absolute;
  float: left;
  left: 280px;
  bottom: 65px;
  background-color: #FFF59D;
  border: 3px solid black;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -1px;
  z-index: 1;
}

.button-cta:hover,
.button-nav-cta:hover,
.button-sec-cta:hover {
  background-color: #272625;
  color: #FFF59D;
  cursor: pointer;
  box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.2);
}

.button-cta:hover {
  border: 3px solid #f3bf02;
}

.button-nav-cta {
  height: 40px;
  background-color: transparent;
  border: 2px solid #272625;
  font-weight: 600;
  font-size: 16px;
  display: none;
  transition: all 0.3s;
}


.second-cta {
  padding: 60px;
  background-color: rgba(243, 191, 2, 1);
  text-align: center;
}

.button-sec-cta {
  height: 46px;
  background-color: #f3bf02;
  border: 3px solid #272625;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -1px;
}

.button-sec-cta:hover {
  color: f3bf02;
}

.features {
  padding: 25px;
}

.features .features-container {
  padding: 35px 0px;
}

.features .features-item {
  width: 31.75%;
  height: 200px;
  margin: 0.5% 0.5%;
  border: 3px solid transparent;
  display: inline-table;
  position: relative;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}

.features .features-item:hover {
  border: 3px solid #f3bf02;
  box-shadow: 14px 14px 24px rgba(0, 0, 0, 0.4);
}

.features .features-item .item-number {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -40px;
  left: 0px;
  font-size: 200px;
  font-weight: 300;
  letter-spacing: -6px;
  color: #181818;
  text-align: center;
  z-index: 0;
  transition: all 0.6s ease-in-out;
}

.features .features-item:hover .item-number {
  color: #f3bf02;
}

.features .features-item .item-text {
  width: 100%;
  padding: 5px;
  position: absolute;
  float: left;
  top: 50%;
  left: 0px;
  font-size: 22px;
  letter-spacing: -1px;
  line-height: 1;
  color: #5e5e5f;
  text-align: center;
  background: rgba(39, 38, 37, 0.8);
  z-index: 1;
  transform: translate(0%, -50%);
}

.features .features-item:hover .item-text {
  color: #5e5e5f;
  background: rgba(39, 38, 37, 1);
}

.coments {
  padding: 35px 25px;
  background-color:#aacfd0;
}
.coments2 {
  padding: 35px 25px 10px 25px;
  background-color:#80a7a8;
}

.box-clients {
  width: 300px;
  height: 200px;
  margin: auto;
  position: relative;
}

p.speech {
  position: relative;
  width: 300px;
  height: 150px;
  text-align: left;
  background-color: #dddfe6;
  padding: 10px;
  margin: auto;
  border-radius: 6px;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.5);
}
 

p.speech:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  top: 150px;
  border: 15px solid;
  border-color: #dddfe6 transparent transparent #dddfe6;
}

.triangle {
  z-index: 10;
  position: relative;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
}

.triangle::before {
  z-index: 10;
  content: "";
  position: absolute;
  top: -20px;
  left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
}

.triangle::after {
  z-index: 10;
  content: "";
  position: absolute;
  top: -20px;
  right: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
}
.right-triangle {
  z-index: 10;
  position: absolute;
  left: 70px;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 50px solid white;
  transform: rotate(90deg);
}

.id-client {
  position: absolute;
  float: left;
  top: 200px;
  color: #5e5e5f;
}

.footer {
  padding: 20px 25px;
  background-color: #607D8B;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.nav2 {
  width: 100%;
  height: 66px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  transition: all 0.3s;
}
.copy {
  font-size: 15px;
  text-align: center;
  color: rgba(243, 191, 2, 1);
}

#scrollup {
  margin: 10px;
  cursor: pointer;
}

.anim_fadeInLeft {
  animation: fadeInLeft 0.3s linear;
}

.anim_fadeInRight {
  animation: fadeInRight 0.3s linear;
}

.icon-credits {
  width: 100%;
  position: absolute;
  bottom: 4px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.08);
  text-align: center;
  z-index: -1;
}

.icon-credits a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.12);
}



.learn-more {
  height: 46px;
  position: absolute;
  float: left;
  left: 40px;
  bottom: -20px;
  background-color: #FFF59D;
  /* border: 3px solid black; */
  /* font-weight: 600; */
  /* font-size: 24px; */
  /* letter-spacing: -1px; */
  z-index: 1;
  /* position: relative;
  position: absolute;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit; */
}

.learn-more {
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 0.5em 0.8em 2.7em 0.8em;
  background: #fff59d;
  border: 2px solid #d6c106;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),;
}

.learn-more::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #b8a605;
  border-radius: inherit;
  /* box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2; */
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.learn-more:hover {
  background: #fae100;
  color: white;
  transform: translate(0, 0.25em);
}

.learn-more:hover::before {
  /* box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2; */
  transform: translate3d(0, 0.5em, -1em);
}

.learn-more:active {
  background: #ffe9e9;
  transform: translate(0em, 0.75em);
}

.learn-more:active::before {
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  transform: translate3d(0, 0, -1em);
}
.choice{
   background-color: #fff59d;border-radius: 5px;width: 100%;height: 100%; white-space: nowrap;color: black;cursor: pointer; 
   display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 또는 원하는 높이 값을 설정 */
   height: 100px;
   transition: all;
   
}
.choice:hover{
  background-color: #9ae4c1;
}
@media screen and (max-width: 767px) {
  .hero{
    padding-top: 150px;
  }
  .cta {
  height: 170px;
    font-size: 25px;
    width: 270px;
    padding: 10px;
  }
  .button-cta {
  left: 150px;
} 
}
</style>
